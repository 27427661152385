const groupedPermissions = {
  Bookings: {
    BOOKING_ADD_PAYMENT: 'Add Payment to Booking',
    BOOKING_ASSIGN_STAFF: 'Assign Team Member to Booking',
    BOOKING_CANCEL: 'Cancel booking',
    BOOKING_CHECK_IN_OUT: 'Check In/Out Booking',
    BOOKING_CREATE: 'Create booking',
    BOOKING_EDIT: 'Edit Booking',
    BOOKING_MANAGE_GROUPS: 'Manage Bookings Group',
    BOOKING_REFUND: 'Refund Booking',
    BOOKING_RESCHEDULE: 'Reschedule Booking',
    BOOKING_VIEW_PRICES: 'View Booking Price',
    BOOKING_VIEW: 'View All Bookings',
    BOOKING_CALL_OWNER: 'View Client Number on Bookings List',
  },

  Clients: {
    CLIENT_CREATE: 'Create Client',
    CLIENT_DELETE: 'Delete Client',
    CLIENT_EDIT: 'Edit Client',
    CLIENT_VIEW: 'View Client',
    CLIENT_VIEW_BOOKINGS: 'View Client Bookings',
  },

  'Clients groups': {
    CLIENT_GROUP_CREATE: 'Create Clients Group',
    CLIENT_GROUP_DELETE: 'Delete Clients Group',
    CLIENT_GROUP_EDIT: 'Edit Clients Group',
    CLIENT_GROUP_VIEW: 'View Clients Group',
  },

  Invoices: {
    INVOICE_CREATE: 'Create Invoice',
    INVOICE_DELETE: 'Delete Invoice',
    INVOICE_EDIT: 'Edit Invoice',
    INVOICE_VIEW: 'View Invoice',
  },

  Pets: {
    PET_CREATE: 'Create Pet',
    PET_DELETE: 'Delete Pet',
    PET_EDIT: 'Edit Pet',
  },

  Services: {
    SERVICE_CREATE: 'Create Service',
    SERVICE_DELETE: 'Delete Service',
    SERVICE_EDIT: 'Edit Service',
    SERVICE_VIEW: 'View Service',
  },

  Team: {
    TEAM_CREATE: 'Create Team Member',
    TEAM_DELETE: 'Delete Team Member',
    TEAM_EDIT: 'Edit Team Member',
    TEAM_VIEW: 'View Team Member',
  },

  Settings: {
    BUSINESS_SETTINGS_EDIT: 'Edit Business Settings',
    SUBSCRIPTION_EDIT: 'Edit Subscription',
    MONEY_EDIT_BANK_ACCOUNT: 'Update Bank Details',
    MONEY_VIEW_BALANCE: 'View Balance',
    PAYOUT_CREATE: 'Create Payout',
    PAYOUT_VIEW: 'View Payout',
  },
}

// Removes "grouping" from list of permissions.
// groupedPermissions are in format: { GroupName: { <permission_name>: <permission_label> } }
// This function creates flat, one level object without groups (example: { <permission_name>: <permission_label> })
function flattenPermissionsObject(nestedPermissions) {
  return Object.values(nestedPermissions).reduce(
    (items, groupPermissions) => ({ ...items, ...groupPermissions }),
    {},
  )
}

const permissionsLabels = flattenPermissionsObject(groupedPermissions)

// Create object containing permission name as key and value
const permissions = Object.fromEntries(Object.keys(permissionsLabels).map((p) => [p, p]))

export { permissions, permissionsLabels, groupedPermissions }
