const CHATWOOT_BASE_URL = 'https://chatwoot.mypetwalker.co.uk'
const CHATWOOT_WEBSITE_TOKEN = 'CEZCz9aF56fKTHGH7SgF3HEE'

const sdkUrl = `${CHATWOOT_BASE_URL}/packs/js/sdk.js`

export const onClientEntry = () => {
  window.chatwootSettings = {
    launcherTitle: 'Chat with us',
    position: 'right',
    locale: 'en',
    type: 'expanded_bubble',
    hideMessageBubble: true,
    darkMode: 'light',
    showPopoutButton: false,
  }

  const loadChatwootSDK = () =>
    new Promise((resolve, reject) => {
      const script = document.createElement('script')
      script.src = sdkUrl
      script.async = true
      script.defer = true
      script.onload = () => {
        resolve(script)
        window.chatwootSDK.run({
          websiteToken: CHATWOOT_WEBSITE_TOKEN,
          baseUrl: CHATWOOT_BASE_URL,
        })
      }
      script.onerror = () => reject(new Error('Failed to load the Chatwoot SDK'))
      document.head.appendChild(script)
      // add css style with .woot-widget-holder { padding-top: 50px; }
      const style = document.createElement('style')
      style.innerHTML = '.woot-widget-holder { padding-top: 50px; }'
      document.head.appendChild(style)
    })

  loadChatwootSDK()
    .then(() => {
      console.log('Chatwoot SDK Loaded Successfully')
    })
    .catch((error) => {
      console.error('Error loading Chatwoot SDK:', error)
    })
}
