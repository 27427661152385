/* eslint no-underscore-dangle: 0 */
import { computed, observable, action, runInAction, makeObservable } from 'mobx'
import IDB from 'helpers/idb'
import viewStore from './viewStore'
import {
  getProfile,
  updateProfile,
  agreeTerms,
  getSetupIntent,
  saveCard,
  removeCard,
  getOtpCode,
  signIn,
  logout,
  uploadProfilePhoto,
} from './api'

class ProfileStore {
  token

  user = {}

  loadingUser

  updatingUser

  uploadingPhoto = false

  error

  constructor() {
    makeObservable(this, {
      token: observable,
      user: observable,
      loadingUser: observable,
      updatingUser: observable,
      uploadingPhoto: observable,
      error: observable,
      roles: computed,
      permissions: computed,
      hasToken: computed,
      hasProfile: computed,
      hasConsents: computed,
      isAdmin: computed,
      isStaff: computed,
      photo: computed,
      getToken: action,
      logout: action,
      getProfile: action,
      updateUser: action,
      agreeTerms: action,
      getSetupIntent: action,
      saveCard: action,
      removeCard: action,
      handleUnauthorized: action,
      uploadPhoto: action,
    })
  }

  get roles() {
    return (this.user && this.user.roles) || {}
  }

  get permissions() {
    return this.user.permissions || []
  }

  get hasToken() {
    return !!this.token
  }

  get hasProfile() {
    if (this.user === false) return false
    return !!this.user && !!this.user._id
  }

  get hasConsents() {
    const { consents } = this.user
    if (this.roles.staff) {
      return consents && consents.termsAndConditionsApp && consents.termsAndConditionsStaff
    }
    if (this.roles.admin) {
      return consents && consents.termsAndConditionsApp
    }
    return consents && consents.termsAndConditions && consents.privacyPolicy
  }

  get isAdmin() {
    return this.roles.admin === true
  }

  get isStaff() {
    return this.roles.staff === true
  }

  get photo() {
    return this.user.photo
  }

  hasRole(allowedRoles) {
    const hasAllowedRole = !!allowedRoles.filter((r) => this.roles[r] === true).length
    return hasAllowedRole
  }

  /**
   * AUTH
   */

  getToken = () => {
    const token = localStorage.getItem('token')
    this.token = token || null
    return token || null
  }

  // Check if user has required permission
  // Accepts string. We assume that admin role can do everything
  hasPermission = (permission) =>
    this.isAdmin || this.permissions.some((perm) => perm === permission)

  sendOtp = (method, value) => getOtpCode({ method, value })

  login = async (method, value, code) => {
    const result = await signIn({ method, value, code })
    if (!result.token) {
      viewStore.notify('danger', 'Invalid sign in')
      return result
    }

    localStorage.setItem('token', result.token)

    return result
  }

  async logout() {
    // first close session in the server
    await logout()

    localStorage.clear()
    IDB.deleteDB()
    runInAction(() => {
      this.token = null
      this.user = false
      this.error = null
    })
  }

  getProfile = async () => {
    try {
      this.loadingUser = true
      this.error = null

      const response = await getProfile()
      runInAction(() => {
        if (!!response && typeof response.user === 'object' && response.user._id) {
          this.user = response.user
        } else {
          this.token = null
          this.user = false
        }

        // Identify user on Clarity
        if (window.clarity) {
          window.clarity('identify', response.user._id, null, null, null)
          window.clarity('set', 'user_phone', response.user.phone)
          window.clarity('set', 'user_email', response.user.email)
          window.clarity('set', 'user_name', response.user.name)
          window.clarity('set', 'user_company', response.user.company)
        }

        this.loadingUser = false
      })
    } catch (e) {
      // this.logout()
      runInAction(() => {
        this.error = 'Loading profile failed'
        this.loadingUser = false
      })
      console.log('profileStore.getProfile()', e.message)
      if (e.message === 'Unauthorized' || e.message === 'Profile not found') {
        viewStore.notify('warning', 'You have to sign in.')
        this.logout()
      }
    }
  }

  async updateUser(data) {
    this.updatingUser = true

    try {
      const response = await updateProfile(data)
      runInAction(() => {
        this.updatingUser = false
        if (!response.error) {
          this.user = { ...this.user, ...response.user }
        }
      })
      return response
    } catch (e) {
      console.log('updateUser failed', e)
      runInAction(() => {
        this.updatingUser = false
      })
      throw e
    }
  }

  async agreeTerms(data) {
    this.updatingUser = true

    try {
      const response = await agreeTerms(data)
      runInAction(() => {
        this.updatingUser = false
        if (!response.error && response.success) {
          this.user = { ...this.user, ...response.user }
        }
      })
      return response
    } catch (e) {
      console.log('agreeTerms failed', e)
      runInAction(() => {
        this.updatingUser = false
      })
      throw e
    }
  }

  async getSetupIntent() {
    this.updatingUser = true

    try {
      const response = await getSetupIntent()

      runInAction(() => {
        this.updatingUser = false
      })
      return response
    } catch (e) {
      console.log('getSetupIntent failed', e)
      runInAction(() => {
        this.updatingUser = false
      })
      throw e
    }
  }

  async saveCard(paymentMethod) {
    this.updatingUser = true

    try {
      const response = await saveCard({ paymentMethod })

      runInAction(() => {
        this.updatingUser = false
        if (!response.error && response.success) {
          this.user = { ...this.user, ...response.user }
        }
      })
      return response
    } catch (e) {
      console.log('saveCard failed', e)
      runInAction(() => {
        this.updatingUser = false
      })
      throw e
    }
  }

  async removeCard() {
    this.updatingUser = true

    try {
      const response = await removeCard()
      runInAction(() => {
        this.updatingUser = false
        if (!response.error && response.success) {
          this.user = { ...response.user }
        }
      })
      return response
    } catch (e) {
      console.log('removeCard failed', e)
      runInAction(() => {
        this.updatingUser = false
      })
      throw e
    }
  }

  handleUnauthorized() {
    viewStore.notify('warning', 'You have to sign in.')
    this.logout()
  }

  uploadPhoto = async (photo) => {
    this.uploadingPhoto = true

    try {
      const data = await uploadProfilePhoto(photo)
      runInAction(() => {
        this.uploadingPhoto = false
        console.log('UPLOADED PHOTO', data)
        if (data.user) {
          this.user = data.user
        }
      })

      viewStore.notify('success', 'Photo uploaded.')

      return data
    } catch (e) {
      console.log('uploadPhoto failed', e)
      runInAction(() => {
        this.uploadingPhoto = false
      })
      return {
        error: e,
        message: e.message || 'Update failed',
      }
    }
  }
}

export default new ProfileStore()
