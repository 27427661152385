import { reaction, computed, observable, action, runInAction, makeObservable } from 'mobx'
import moment from 'moment-timezone'
import * as Sentry from '@sentry/react'

import profileStore from 'stores/profileStore'
import viewStore from 'stores/viewStore'
import {
  getCompany,
  updateCompany,
  getStripeAccount,
  updateStripeAccount,
  getAccountBalance,
  getOnboardingLink,
  getPayout,
  getPayouts,
  doPayout,
  uploadCompanyLogo,
  deleteCompanyLogo,
  getMySubscription,
  getPaymentMethods,
} from 'stores/api'
import { permissions } from '../helpers/permissions'

const STRIPE_CHECK_INTERVAL =
  process.env.GATSBY_ENV === 'production' ? 30 * 60 * 1000 : 6 * 60 * 60 * 1000 // 30min on prod, 6h on dev
const TRIAL_CHECK_INTERVAL =
  process.env.GATSBY_ENV === 'production' ? 60 * 60 * 1000 : 6 * 60 * 60 * 1000 // 60min on prod, 6h on dev

class CompanyStore {
  company = {}

  stripeAccount = {}

  card = {}

  cards = []

  loadingCompany

  updatingCompany

  loadingAccount

  updatingAccount

  updatingCard

  uploadingLogo = false

  balance = {
    available: 0,
    pending: 0,
  }

  payouts = []

  payout = {}

  onboarding = {}

  loadingBalance

  loadingOnboarding

  loadingPayouts

  loadingPayout

  loadingPaymentMethods

  paymentMethods = []

  subscription = {}

  loadingSubscription

  stripeNotification

  trialNotification

  error

  get hasCompany() {
    return this.company.name
  }

  get companyLogo() {
    return this.company.logo
  }

  get hasStripeAccount() {
    return this.stripeAccount.id
  }

  get hasSubscription() {
    return this.company.subscriptionId && this.company.subscription
  }

  get hadTour() {
    return this.company.hadTour
  }

  get hasLoadedSubscription() {
    return this.subscription._id && this.subscription.status
  }

  get hasActiveSubscription() {
    return ['trialing', 'active', 'past_due'].includes(this.subscription.status)
  }

  get maxStaff() {
    return this.subscription && this.subscription.maxStaff ? this.subscription.maxStaff : 0
  }

  get requirements() {
    if (!this.hasStripeAccount) return {}
    return this.stripeAccount.requirements
  }

  // Get future_requirements object from Stripe account details
  get futureRequirements() {
    if (!this.hasStripeAccount) return {}
    return this.stripeAccount.future_requirements
  }

  get fieldsNeeded() {
    if (!this.hasStripeAccount || !this.stripeAccount.requirements) return []
    return this.requirements.past_due
  }

  // Stripe Connect account need update if has some of the requirements due
  get needAccountUpdate() {
    return (
      (this.requirements.past_due && this.requirements.past_due.length) ||
      (this.requirements.currently_due && this.requirements.currently_due.length) ||
      (this.futureRequirements.currently_due && this.futureRequirements.currently_due.length) ||
      this.requirements.current_deadline
    )
  }

  get accountType() {
    if (this.hasStripeAccount) {
      return this.stripeAccount.business_type
    }
    return null
  }

  get bankAccount() {
    if (
      this.hasStripeAccount &&
      this.stripeAccount.external_accounts &&
      this.stripeAccount.external_accounts.data &&
      this.stripeAccount.external_accounts.data.length
    ) {
      return this.stripeAccount.external_accounts.data[0]
    }
    return null
  }

  get allowCreatingBookings() {
    return this.hasCompany && this.company.allowCreatingBookings
  }

  get settings() {
    return this.hasCompany && this.company.settings ? this.company.settings : {}
  }

  constructor() {
    makeObservable(this, {
      company: observable,
      stripeAccount: observable,
      card: observable,
      cards: observable,
      loadingCompany: observable,
      updatingCompany: observable,
      loadingAccount: observable,
      updatingAccount: observable,
      updatingCard: observable,
      uploadingLogo: observable,
      balance: observable,
      payouts: observable,
      payout: observable,
      onboarding: observable,
      loadingBalance: observable,
      loadingOnboarding: observable,
      loadingPayouts: observable,
      loadingPayout: observable,
      loadingPaymentMethods: observable,
      paymentMethods: observable,
      subscription: observable,
      loadingSubscription: observable,
      stripeNotification: observable,
      trialNotification: observable,
      error: observable,
      hasCompany: computed,
      companyLogo: computed,
      hasStripeAccount: computed,
      hasSubscription: computed,
      hadTour: computed,
      hasLoadedSubscription: computed,
      hasActiveSubscription: computed,
      maxStaff: computed,
      requirements: computed,
      futureRequirements: computed,
      fieldsNeeded: computed,
      needAccountUpdate: computed,
      accountType: computed,
      bankAccount: computed,
      allowCreatingBookings: computed,
      settings: computed,
      getMyCompany: action,
      updateCompany: action,
      clearCompany: action,
      getStripeAccount: action,
      updateAccount: action,
      getAccountBalance: action,
      getOnboardingLink: action,
      getPayouts: action,
      getPayout: action,
      doPayout: action,
      uploadLogo: action,
      deleteLogo: action,
      getMySubscription: action,
      getPaymentMethods: action,
      hideStripeNotification: action,
    })

    reaction(
      () => profileStore.hasProfile,
      () => {
        if (profileStore.hasProfile) this.getMyCompany()
      },
    )
    reaction(
      () => profileStore.isAdmin || profileStore.isStaff,
      () => {
        if (profileStore.isAdmin || profileStore.isStaff) this.getStripeAccount()
      },
    )

    reaction(
      () => (this.hasStripeAccount && profileStore.isAdmin) || profileStore.isStaff,
      () => {
        setTimeout(this.checkStripe, 3 * 60 * 1000)
        setInterval(this.checkStripe, STRIPE_CHECK_INTERVAL)
      },
    )

    reaction(
      () => this.hasLoadedSubscription && profileStore.isAdmin,
      () => {
        setTimeout(this.checkTrial, 6 * 60 * 1000)
        setInterval(this.checkTrial, TRIAL_CHECK_INTERVAL)
      },
    )

    reaction(
      () => this.hasCompany && profileStore.hasProfile,
      () => {
        if (!process.env.GATSBY_IS_LOCAL) {
          console.log('########### IDENTIFY USER ###########')
          // const role = Object.keys(profileStore.roles).find((r) => profileStore.roles[r] === true) || 'client'

          // Setup MS Clarity here

          Sentry.setUser({
            id: profileStore.user._id,
            name: profileStore.user.name,
            email: profileStore.user.email,
            phone: profileStore.user.phone,
          })

          Sentry.setContext('company', {
            id: this.company._id,
            name: this.company.name,
            userPhoneNumber: profileStore.user.phone,
          })
        }
      },
    )
  }

  getMyCompany = async () => {
    try {
      this.loadingCompany = true
      this.error = null
      const response = await getCompany(profileStore.user.company)
      runInAction(() => {
        this.company = response.company || {}
        this.subscription = { ...this.subscription, ...this.company.subscription }
        this.loadingCompany = false
      })
    } catch (e) {
      runInAction(() => {
        this.error = 'Loading company failed'
        this.loadingCompany = false
      })
    }
  }

  async updateCompany(data) {
    this.updatingCompany = true

    try {
      const response = await updateCompany(data)
      runInAction(() => {
        this.updatingCompany = false
        if (response.success) {
          this.company = { ...this.company, ...response.company }
        }
      })
      return response
    } catch (e) {
      console.log('updateCompany failed', e)
      runInAction(() => {
        this.updatingCompany = false
      })
      return {
        error: e,
        message: e.message || 'Update failed',
      }
    }
  }

  clearCompany() {
    this.company = {}
    this.error = null
  }

  /**
   * STRIPE ACCOUNT
   */

  getStripeAccount = async () => {
    this.loadingAccount = true
    try {
      const response = await getStripeAccount()
      runInAction(() => {
        if (response.success) {
          this.stripeAccount = response.account
        } else {
          this.stripeAccount = {}
        }
        this.loadingAccount = false
      })
    } catch (e) {
      console.log('getStripeAccount error', e)
      runInAction(() => {
        this.loadingAccount = false
        this.stripeAccount = {}
      })
    }
  }

  async updateAccount(data) {
    this.updatingAccount = true

    try {
      const response = await updateStripeAccount(data)
      runInAction(() => {
        this.updatingAccount = false
        if (!response.error) {
          this.stripeAccount = { ...this.stripeAccount, ...response.account }
        }
      })
      return response
    } catch (e) {
      console.log('updateCompany failed', e)
      runInAction(() => {
        this.updatingCompany = false
      })
      return {
        error: e,
        message: e.message || 'Update failed',
      }
    }
  }

  async getAccountBalance() {
    try {
      this.loadingBalance = true
      this.error = null
      const response = await getAccountBalance()
      console.log('getAccountBalance', response)
      runInAction(() => {
        if (response.success) {
          this.balance = response.balance
        }
        this.loadingBalance = false
      })
    } catch (e) {
      runInAction(() => {
        this.error = 'Loading balance failed'
        this.loadingBalance = false
      })
    }
  }

  getOnboardingLink = async () => {
    this.loadingOnboarding = true
    const response = await getOnboardingLink()
    runInAction(() => {
      if (response.success) {
        this.onboarding = response
      }
      this.loadingOnboarding = false
    })
    return response
  }

  async getPayouts() {
    try {
      this.loadingPayouts = true
      const response = await getPayouts()
      runInAction(() => {
        this.payouts = response.payouts || []
        this.loadingPayouts = false
      })
      return response
    } catch (e) {
      console.log('getPayouts failed', e)
      runInAction(() => {
        this.loadingPayouts = false
      })
      return {
        error: e,
        message: e.message || 'Loading payouts failed',
      }
    }
  }

  async getPayout(id) {
    try {
      this.loadingPayout = true
      const response = await getPayout(id)
      runInAction(() => {
        this.payouts = response || {}
        this.loadingPayout = false
      })
      return response
    } catch (e) {
      console.log('getPayout failed', e)
      runInAction(() => {
        this.loadingPayout = false
      })
      return {
        error: e,
        message: e.message || 'Loading payout failed',
      }
    }
  }

  async doPayout() {
    try {
      this.loadingPayout = true
      const response = await doPayout()
      runInAction(() => {
        this.payout = response.payout
        this.loadingPayout = false
      })
      return response
    } catch (e) {
      console.log('doPayout failed', e)
      runInAction(() => {
        this.loadingPayout = false
      })
      return {
        error: e,
        message: e.message || 'Payout creation failed',
      }
    }
  }

  uploadLogo = async (logo) => {
    this.uploadingLogo = true

    try {
      const data = await uploadCompanyLogo(logo)
      runInAction(() => {
        this.uploadingLogo = false
        console.log('UPLOADED PHOTO', data)
        if (data.company) {
          this.company = data.company
        }
      })

      viewStore.notify('success', 'Logo uploaded.')

      return data
    } catch (e) {
      console.log('uploadLogo failed', e)
      runInAction(() => {
        this.uploadingLogo = false
      })
      return {
        error: e,
        message: e.message || 'Update failed',
      }
    }
  }

  deleteLogo = async () => {
    try {
      const result = await deleteCompanyLogo()
      console.log('deleteLogo', result)
      if (!result.company.logo) {
        viewStore.notify('success', 'Logo removed.')
        runInAction(() => {
          this.company.logo = null
        })
      } else {
        viewStore.notify('warning', 'Logo not removed.')
      }
      return result
    } catch (e) {
      console.log('deleteLogo error', e)
      // viewStore.notify('warning', `Logo not removed.`)
      return { success: false }
    }
  }

  async getMySubscription() {
    try {
      this.loadingSubscription = true
      const response = await getMySubscription()

      runInAction(() => {
        this.subscription = response.subscription || {}
        this.loadingSubscription = false
      })
    } catch (e) {
      console.log('getMySubscription error', e)
      viewStore.notify('warning', e.message || 'Loading subscription error')
      runInAction(() => {
        this.subscription = {}
        this.loadingSubscription = false
      })
    }
  }

  async getPaymentMethods() {
    try {
      this.loadingPaymentMethods = true
      const response = await getPaymentMethods()
      runInAction(() => {
        this.paymentMethods = response.paymentMethods || []
        this.loadingPaymentMethods = false
      })
      return response
    } catch (e) {
      console.log('getPayout failed', e)
      runInAction(() => {
        this.loadingPaymentMethods = false
      })
      return {
        error: e,
        message: e.message || 'Loading payout failed',
      }
    }
  }

  getStripeNotificationMessage = () => {
    const { requirements, futureRequirements } = this

    let message = null
    // 'Online payments or payouts are disabled. Please verify your account.'

    if (requirements.disabled_reason === 'requirements.past_due') {
      // keep above message
    } else if (requirements.disabled_reason === 'requirements.pending_verification') {
      message = 'Your business verification is in progress.'
    } else if (requirements.disabled_reason) {
      message = 'Stripe account disabled - contact support for more informations'
    } else if (
      requirements.current_deadline &&
      moment(requirements.current_deadline * 1000).diff(Date.now(), 'days') <= 4
    ) {
      message = 'Please verify your Stripe account to activate online payments'
    } else if (requirements.past_due && requirements.past_due.length) {
      message = 'Please verify your Stripe account to enable online payments'
      // If has some due future requirements:
    } else if (futureRequirements.currently_due?.length) {
      message = 'Please update your Stripe account to keep accepting online payments'
    }

    return message
  }

  checkStripe = action(() => {
    // If profile hasn't loaded it or doesn't have permission,
    // or doesn't use online payments as default payment method
    // don't check Stripe
    //
    // TODO: Maybe we should check Stripe even if it's not default.
    // Some businesses probably would like to use it only for few clients
    if (
      !profileStore.hasProfile ||
      !(profileStore.isAdmin || profileStore.hasPermission(permissions.MONEY_EDIT_BANK_ACCOUNT)) ||
      ['PAY_UPON_BOOKING', 'INVOICED_PAYMENT'].includes(this.default_payment_method)
    )
      return

    const createdAt = this.company.created_date || this.company.createdAt
    if (moment().isBefore(moment(createdAt).add(1, 'day'))) return

    const hideUntil = localStorage.getItem('hideStripeNotificationUntil')

    // If is before "hideStripeNotificationUntil", keep hidden
    if (hideUntil && Date.now() < parseInt(hideUntil, 10)) {
      this.stripeNotification = null
      return
    }

    if (!this.hasStripeAccount || !this.needAccountUpdate) {
      this.stripeNotification = null
      return
    }

    if (this.needAccountUpdate) {
      this.stripeNotification = this.getStripeNotificationMessage()
    }
  })

  hideStripeNotification() {
    this.stripeNotification = null
  }

  checkTrial = action(() => {
    const { hasLoadedSubscription, subscription } = this
    // console.log('checkTrial', subscription.default_payment_method)
    if (!profileStore.isAdmin) return

    const createdAt = this.company.created_date || this.company.createdAt
    if (moment().isBefore(moment(createdAt).add(1, 'day'))) return

    const hideUntil = localStorage.getItem('hideTrialNotificationUntil')
    // If is before "hideTrialNotificationUntil", keep hidden
    if (hideUntil && Date.now() < parseInt(hideUntil, 10)) {
      return
    }

    // if subscription isn't loaded or there's payment method, don't show popup
    if (!hasLoadedSubscription || subscription.default_payment_method) {
      this.trialNotification = false
      return
    }

    // if (!subscription.default_payment_method) {
    this.trialNotification = true
    // }
  })

  hideTrialNotification = action(() => {
    this.trialNotification = false
  })
}

export default new CompanyStore()
